import * as React from 'react';
import type { SVGProps } from 'react';
const SvgBullishMedium = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='1em'
		height='1em'
		className='svg-icon'
		{...props}>
		<path
			fill='#09bf09'
			stroke='#0ac90a'
			d='m3.037 9.249 6.324-7.9 6.324 7.9h-3.162v7.938H6.2V9.249H3.037z'
		/>
	</svg>
);
export default SvgBullishMedium;
