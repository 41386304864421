import * as React from 'react';
import type { SVGProps } from 'react';
const SvgAccumulationLittle = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='1em'
		height='1em'
		className='svg-icon'
		{...props}>
		<path
			fill='#00bf00'
			stroke='#00bf00'
			d='m3.799 3.172 6.271-.985-1.201 6.234L7.6 7.108l-3.754 3.627L1.312 8.11l3.754-3.626L3.8 3.172z'
		/>
	</svg>
);
export default SvgAccumulationLittle;
