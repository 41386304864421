import * as React from 'react';
import type { SVGProps } from 'react';
const SvgReboundMedium = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='1em'
		height='1em'
		className='svg-icon'
		{...props}>
		<path
			fill='#bf0000'
			stroke='#bf0000'
			d='m14.26 5.388.91 10.078-10.033-1.318 2.28-2.19L1.92 6.232l4.562-4.38 5.498 5.726 2.28-2.19z'
		/>
	</svg>
);
export default SvgReboundMedium;
