import * as React from 'react';
import type { SVGProps } from 'react';
const SvgRebound = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='1em'
		height='1em'
		className='svg-icon'
		{...props}>
		<path
			fill='#bf0000'
			stroke='#bf0000'
			d='m18.292 7.792 2.157 13.1-12.82-3.455 2.666-2.411L2.77 6.708l5.331-4.823 7.524 8.318 2.666-2.411z'
		/>
	</svg>
);
export default SvgRebound;
