import React, { lazy } from 'react';
import { authPagesMenu, topPagesMenu } from '../menu';
import Login from '../pages/presentation/auth/Login';

const AUTH = {
	PAGE_404: lazy(() => import('../pages/presentation/auth/Page404')),
};

const SCREENER_LIST = {
	BOXED: lazy(() => import('../pages/presentation/screener/ScreenerBrowseView')),
};

const SCREENERS_INDEX = {
	BOXED: lazy(() => import('../pages/presentation/predefined-screeners/ScreenersIndexPage')),
};

const SCREENER_NEW_HIGHS_LOWS = {
	BOXED: lazy(() => import('../pages/presentation/predefined-screeners/NewHighsLowsPage')),
};

const SCREENER_TOP_LOSERS_GAINERS = {
	BOXED: lazy(() => import('../pages/presentation/predefined-screeners/TopGainersLosersPage')),
};

const SCREENER_UP_DOWN_BIG_VOLUME = {
	BOXED: lazy(() => import('../pages/presentation/predefined-screeners/UpDownBigVolumePage')),
};

const SCREENER_GOLDEN_CROSS = {
	BOXED: lazy(() => import('../pages/presentation/predefined-screeners/GoldenCrossPage')),
};

const SCREENER_MARK_MINERVINI = {
	BOXED: lazy(() => import('../pages/presentation/predefined-screeners/MarkMinerviniPage')),
};

const SCREENER_DOJI = {
	BOXED: lazy(() => import('../pages/presentation/predefined-screeners/DojiPage')),
};

const SECTORS_LIST = {
	BOXED: lazy(() => import('../pages/presentation/sectors/SectorsListPage')),
};

const INDEXES_LIST = {
	BOXED: lazy(() => import('../pages/presentation/indexes/IndexesListPage')),
};

const INDEX_COMPONENTS_LIST = {
	BOXED: lazy(() => import('../pages/presentation/indexes/IndexComponentsPage')),
};

const WATCH_LIST = {
	BOXED: lazy(() => import('../pages/presentation/watchlist/WatchListPage')),
};

const INSTRUMENT = {
	BOXED: lazy(() => import('../pages/presentation/instruments/InstrumentPage')),
};

const INSTRUMENT_ALL_CHARTS = {
	BOXED: lazy(() => import('../pages/presentation/instruments/InstrumentCandlestickTripleChart')),
};

const SECTOR_STOCKS_LIST = {
	BOXED: lazy(() => import('../pages/presentation/sectors/SectorStocksPage')),
};

const MARKET_DASHBOARD = {
	BOXED: lazy(() => import('../pages/presentation/market/MarketPage')),
};

const MARKET_BREADTH = {
	BOXED: lazy(() => import('../pages/presentation/market/MarketBreadthPage')),
};

const AD_LINES_INDEXES = {
	BOXED: lazy(() => import('../pages/presentation/market/AdLineIndexes')),
};

const presentation = [
	/**
	 * Landing
	 */
	{
		path: ``,
		element: <MARKET_BREADTH.BOXED />,
	},
	{
		path: `markets`,
		element: <MARKET_DASHBOARD.BOXED />,
	},
	{
		path: `market-breadth`,
		element: <MARKET_BREADTH.BOXED />,
	},
	{
		path: `market-breadth/ad-line`,
		element: <AD_LINES_INDEXES.BOXED />,
	},

	{
		path: topPagesMenu.screeners.subMenu.advanced.path,
		element: <SCREENER_LIST.BOXED />,
	},
	{
		path: topPagesMenu.screeners.subMenu.predefined.path,
		element: <SCREENERS_INDEX.BOXED />,
	},
	{
		path: topPagesMenu.indexes.path,
		element: <INDEXES_LIST.BOXED />,
	},
	{
		path: `index/:symbol/components`,
		element: <INDEX_COMPONENTS_LIST.BOXED />,
	},
	{
		path: topPagesMenu.sectors.path,
		element: <SECTORS_LIST.BOXED />,
	},
	{
		path: `stocks/:symbol`,
		element: <INSTRUMENT.BOXED />,
	},
	{
		path: `stocks/:symbol/all-charts`,
		element: <INSTRUMENT_ALL_CHARTS.BOXED />,
	},
	{
		path: `sector/:name/components`,
		element: <SECTOR_STOCKS_LIST.BOXED />,
	},
	{
		path: 'screeners/top-gainers-losers',
		element: <SCREENER_TOP_LOSERS_GAINERS.BOXED />,
	},
	{
		path: 'screeners/new-highs-lows',
		element: <SCREENER_NEW_HIGHS_LOWS.BOXED />,
	},
	{
		path: 'screeners/up-down-big-volume',
		element: <SCREENER_UP_DOWN_BIG_VOLUME.BOXED />,
	},
	{
		path: 'screeners/golden-cross',
		element: <SCREENER_GOLDEN_CROSS.BOXED />,
	},
	{
		path: 'screeners/minervini',
		element: <SCREENER_MARK_MINERVINI.BOXED />,
	},
	{
		path: 'screeners/doji',
		element: <SCREENER_DOJI.BOXED />,
	},
	{
		path: 'watchlist',
		element: <WATCH_LIST.BOXED />,
	},

	{
		path: authPagesMenu.page404.path,
		element: <AUTH.PAGE_404 />,
	},
	{
		path: authPagesMenu.login.path,
		element: <Login />,
	},
	{
		path: authPagesMenu.signUp.path,
		element: <Login isSignUp />,
	},
];
const contents = [...presentation];

export default contents;
