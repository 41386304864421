import * as React from 'react';
import type { SVGProps } from 'react';
const SvgReliabilityLow = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='1em'
		height='1em'
		viewBox='0 0 24 24'
		className='svg-icon'
		{...props}>
		<path
			d='M12.012 21.488 23.64 7C23.19 6.66 18.715 3 12 3S.809 6.66.36 7l11.628 14.488.012.012Zm0 0'
			style={{
				stroke: 'none',
				fillRule: 'nonzero',
				fill: '#fb8080',
				fillOpacity: 0.301961,
			}}
		/>
		<path
			d='m6.672 14.86 5.324 6.636.004.004.004-.004 5.324-6.637C17.066 14.656 15.031 13 12 13s-5.066 1.656-5.328 1.86Zm0 0'
			style={{
				stroke: 'none',
				fillRule: 'nonzero',
				fill: '#fb8080',
				fillOpacity: 1,
			}}
		/>
	</svg>
);
export default SvgReliabilityLow;
