import React from 'react';

const BuyMeCoffee = () => (
	<a
		href='https://buymeacoffee.com/xescuder'
		target='_blank'
		rel='noopener noreferrer'
		className='btn btn-primary'>
		Buy Me a Coffee
	</a>
);

export default BuyMeCoffee;
