import * as React from 'react';
import type { SVGProps } from 'react';
const SvgReboundLittle = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='1em'
		height='1em'
		className='svg-icon'
		{...props}>
		<path
			fill='#bf0000'
			stroke='#bf0000'
			d='m8.783 3.787 1.294 6.186-6.282-.685 1.247-1.375L1.235 4.46l2.494-2.75 3.807 3.451 1.247-1.375z'
		/>
	</svg>
);
export default SvgReboundLittle;
