import * as React from 'react';
import type { SVGProps } from 'react';
const SvgBearishLittle = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='1em'
		height='1em'
		className='svg-icon'
		{...props}>
		<path
			fill='#e80b0b'
			stroke='#e20b0b'
			d='m9.697 5.987-3.763 5.078-3.663-5.15 1.857.018.05-5.139L7.89.83 7.84 5.97l1.856.018z'
		/>
	</svg>
);
export default SvgBearishLittle;
