import * as React from 'react';
import type { SVGProps } from 'react';
const SvgAccumulationMedium = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='1em'
		height='1em'
		className='svg-icon'
		{...props}>
		<path
			fill='#00bf00'
			stroke='#00bf00'
			d='m4.804 4.883 10.03-1.338-.89 10.08-2.284-2.186-5.487 5.737-4.57-4.37 5.486-5.738-2.285-2.185z'
		/>
	</svg>
);
export default SvgAccumulationMedium;
