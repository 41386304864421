import * as React from 'react';
import type { SVGProps } from 'react';
const SvgReliabilityHigh = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='1em'
		height='1em'
		viewBox='0 0 24 24'
		className='svg-icon'
		{...props}>
		<path
			d='M12.012 21.488 23.64 7C23.19 6.66 18.715 3 12 3S.809 6.66.36 7l11.628 14.488.012.012Zm0 0'
			style={{
				stroke: 'none',
				fillRule: 'nonzero',
				fill: '#69d290',
				fillOpacity: 0.301961,
			}}
		/>
		<path
			d='m3.535 10.953 8.461 10.535.004.012.012-.012 8.457-10.535C20.039 10.625 16.809 8 12 8c-4.816 0-8.04 2.625-8.465 2.953Zm0 0'
			style={{
				stroke: 'none',
				fillRule: 'nonzero',
				fill: '#69d290',
				fillOpacity: 1,
			}}
		/>
	</svg>
);
export default SvgReliabilityHigh;
