import * as React from 'react';
import type { SVGProps } from 'react';
const SvgBearishMedium = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='1em'
		height='1em'
		className='svg-icon'
		{...props}>
		<path
			fill='#ef0e0e'
			stroke='#f20909'
			d='m15.577 9.089-6.28 7.935L2.93 9.16l3.162-.018-.045-7.937 6.324-.036.045 7.938 3.162-.018z'
		/>
	</svg>
);
export default SvgBearishMedium;
