import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import Input from './bootstrap/forms/Input';
import { useFindStocksBySymbolOrName } from '../utils/services';
import Icon from './icon/Icon';

const highlight = (text, query) => {
	if (!query) return text;

	// Crea una expressió regular per trobar la coincidència (case-insensitive)
	const regex = new RegExp(`(${query})`, 'gi');
	const parts = text.split(regex);

	return parts.map((part, index) =>
		regex.test(part) ? (
			<strong key={`${part}-${index}-${text}`} style={{ fontWeight: 'bold' }}>
				{part}
			</strong>
		) : (
			part
		),
	);
};

const Search = () => {
	const refSearchInput = useRef(null);
	const navigate = useNavigate();
	const [filterResult, setFilterResult] = useState([]);
	const [isDropdownVisible, setIsDropdownVisible] = useState(false);

	const formik = useFormik({
		initialValues: {
			searchInput: '',
		},
		onSubmit: () => {
			setIsDropdownVisible(true);
		},
	});

	// Use the useFindStocksBySymbolOrName hook
	const { items, isLoading } = useFindStocksBySymbolOrName(
		formik.values.searchInput,
		formik.values.searchInput.length > 0, // Fetch only if the input is not empty
	);

	useEffect(() => {
		// Update filterResult when items change
		if (items && formik.values.searchInput.length > 0) {
			setFilterResult(items);
			setIsDropdownVisible(true);
		}

		// Handle clicks outside the dropdown
		const handleClickOutside = (event) => {
			if (refSearchInput.current && !refSearchInput.current.contains(event.target)) {
				setIsDropdownVisible(false);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [items, formik.values.searchInput, refSearchInput]);

	const handleItemClick = (symbol) => {
		formik.setFieldValue('searchInput', symbol); // Actualitza el camp de cerca
		setIsDropdownVisible(false); // Tanca el desplegable
		navigate(`../stocks/${symbol}`); // Navega a la pàgina del símbol
	};

	return (
		<div className='position-relative' ref={refSearchInput}>
			<div className='d-flex align-items-center' data-tour='search'>
				{/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
				<caption
					htmlFor='searchInput'
					className='me-2 mt-0 text-muted mb-0'
					style={{ fontSize: '12px', fontWeight: 'normal', marginBottom: '0' }}>
					Search
				</caption>
				<Input
					id='searchInput'
					type='search'
					className='border-1'
					placeholder='Search stock...'
					onChange={formik.handleChange}
					value={formik.values.searchInput}
					size='sm'
					autoComplete='off'
				/>
			</div>
			{isDropdownVisible && (
				<div
					className='dropdown-menu show position-absolute'
					style={{
						zIndex: 1050,
						top: '100%',
						right: 0,
						width: '190%', // Assegura que el contenidor ocupa el 100% de l'amplada del pare
					}}>
					{isLoading && formik.values.searchInput.length > 0 && (
						<p className='dropdown-item'>Loading...</p>
					)}
					{!isLoading && filterResult.length > 0 && (
						<table className='table border-0 table-hover table-sm'>
							<tbody>
								{filterResult.slice(0, 10).map((item) => {
									const isValidImage = /\.(jpg|jpeg|png|svg|gif)$/i.test(
										item.logoUrl,
									);
									return (
										<tr
											key={item.symbol}
											className='cursor-pointer'
											onClick={() => handleItemClick(item.symbol)}>
											<td style={{ width: '50px' }}>
												{isValidImage && (
													<img
														src={item.logoUrl}
														alt={`${item.symbol} logo`}
														style={{
															width: '30px',
															height: '30px',
															objectFit: 'contain',
														}}
													/>
												)}
											</td>
											<td>
												{highlight(item.symbol, formik.values.searchInput)}
											</td>
											<td>
												{highlight(item.name, formik.values.searchInput)}
											</td>
										</tr>
									);
								})}
								{filterResult.length > 10 && (
									<tr className='text-muted'>
										<td colSpan='3' className='text-center'>
											And {filterResult.length - 10} more results...
										</td>
									</tr>
								)}
							</tbody>
						</table>
					)}
					{!isLoading && filterResult.length === 0 && (
						<p className='dropdown-item'>
							No stock found with symbol or name "{formik.values.searchInput}"
						</p>
					)}
				</div>
			)}
		</div>
	);
};

export default Search;
