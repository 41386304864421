import * as React from 'react';
import type { SVGProps } from 'react';
const SvgBearish = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='1em'
		height='1em'
		className='svg-icon'
		{...props}>
		<path
			fill='#de0000'
			stroke='#de0000'
			d='m5.105 12.271 7.108-10.974 7.107 10.974h-3.553v11.027H8.659V12.27H5.105z'
			style={{
				vectorEffect: 'non-scaling-stroke',
			}}
			transform='rotate(179.792 12.213 12.297)'
		/>
	</svg>
);
export default SvgBearish;
