import axios from 'axios';
import useSWR from 'swr';
import useSWRImmutable from 'swr/immutable';
import { gql, request } from 'graphql-request';
import { config } from '../config';

const BASE_URL = config.apiUrl;
const API_GRAPHQL_URI = config.apiGraphqlUri;

axios.defaults.withCredentials = true;
const httpClient = axios.create({
	baseURL: BASE_URL,
	timeout: 600000,
	headers: { 'Content-Type': 'application/json' },
});

export const fetcher = async (url) => {
	return httpClient.get(url).then((res) => res.data);
};

export const fetcherPost = async ({ url, args }) => {
	if (args) return httpClient.post(url, args).then((res) => res.data);
	return httpClient.get(url).then((res) => res.data);
};

export const fetcherBlob = async (url) => {
	return httpClient.get(url, { responseType: 'blob' }).then((res) => res.data);
};

export const fetcherGraphQL = (query) => request(`${API_GRAPHQL_URI}`, query);

const useBars = (symbol, resolution, countback, indicators = []) => {
	const indicatorsParam = indicators.length > 0 ? indicators.join(',') : '';

	const url = `/bars/${symbol}?countback=${countback}&resolution=${resolution}&firstdatarequest=true&indicators=${indicatorsParam}`;
	const { data, error } = useSWRImmutable(url, fetcher);
	return {
		data,
		isLoading: !error && !data,
		error,
	};
};

const useAllFilters = (timeframe) => {
	const { data, error, isLoading } = useSWRImmutable(
		`/screener/filters/${timeframe}`,
		fetcher,
		{},
	);
	return {
		filters: data,
		isLoading,
		error,
	};
};

const useFearGreed = () => {
	const { data, error, isLoading } = useSWRImmutable(`/fear-greed`, fetcher, {});
	return {
		data,
		isLoading,
		error,
	};
};

const useBusinessCycle = () => {
	const { data, error, isLoading } = useSWRImmutable(`/business-cycle`, fetcher, {});
	return {
		data,
		isLoading,
		error,
	};
};

const useChartImage = (symbol) => {
	const url = `/charts/image/${symbol}/daily`;
	const { data, error } = useSWR(url, fetcherBlob);
	return {
		data,
		isLoading: !error && !data,
		error,
	};
};

const useMarkdown = (filename) => {
	const url = `/docs/markdown/${filename}`;
	const { data, error } = useSWRImmutable(url, fetcher);
	return {
		data,
		isLoading: !error && !data,
		error,
	};
};

const useStockQL = (symbol) => {
	const queryQL = gql`
		query MyQuery {
			stock(symbol: "${symbol}") {			
				symbol
				name
				gicSector
				gicIndustry
				logoUrl
				description	
				percChangeOneDay
				percChangeOneWeek
				percChangeOneMonth
				percChangeThreeMonths
				percChangeOneYear
				percChangeThreeYears
				percChangeFiveYears			
			}
		}
		`;

	const shouldFetch = symbol !== null;
	const { data, error } = useSWRImmutable(shouldFetch ? queryQL : null, fetcherGraphQL, {});
	return {
		stock: data && data.stock ? data.stock : data,
		isLoading: !error && !data,
		error,
	};
};

const useIndexesSymbolsQL = () => {
	const queryQL = `
		query MyQuery {
			indexes {
				symbol
				name
			}
		}
	`;

	const { data, error } = useSWRImmutable(queryQL, fetcherGraphQL, {});
	return {
		data: data && data.indexes ? data.indexes : data,
		isLoading: !error && !data,
		error,
	};
};

const useExchangesQL = () => {
	const queryQL = `
		query MyQuery {
			exchanges {
				symbol
				name
			}
		}
	`;

	const { data, error } = useSWRImmutable(queryQL, fetcherGraphQL, {});
	return {
		exchanges: data && data.exchanges ? data.exchanges : data,
		isLoading: !error && !data,
		error,
	};
};

const useBarsPeriodQL = (shouldLoad, symbol, period = '1y', resolution = '1d') => {
	const queryQL = `
		query MyQuery {
			barsPeriod(period: "${period}", symbol: "${symbol}", resolution: "${resolution}") {
				date
				open
				high
				low
				close
				volume				
			}
		}
	`;

	const { data, error } = useSWRImmutable(shouldLoad ? queryQL : null, fetcherGraphQL, {});

	return {
		data: data && data.barsPeriod ? data.barsPeriod : data,
		isLoading: !error && !data,
		error,
	};
};

const useBarsQL = (shouldLoad, symbol, countback, resolution = '1d', indicators = []) => {
	const indicatorsStr = `[${indicators.map((indicator) => `"${indicator}"`).join(',')}]`;

	const queryQL = `
		query MyQuery {
			bars(countback: ${countback}, symbol: "${symbol}", resolution: "${resolution}", indicators: ${indicatorsStr}) {
				date
				open
				high
				low
				close
				volume
				sma24
				sma50
				sma100
				sma200
			}
		}
	`;

	const { data, error } = useSWRImmutable(shouldLoad ? queryQL : null, fetcherGraphQL, {});

	return {
		bars: data && data.bars ? data.bars : data,
		isLoading: !error && !data,
		error,
	};
};

const useIndexesBarsQL = (shouldLoad, period = '3M', resolution = '1d') => {
	const queryQL = `
		query MyQuery {
			indexesBars(period: "${period}", resolution: "${resolution}") {
				symbol
				name
				bars {
					date
					cumPctChange
				}	
			}
		}
	`;

	const { data, error } = useSWRImmutable(shouldLoad ? queryQL : null, fetcherGraphQL, {});

	return {
		bars: data && data.indexesBars ? data.indexesBars : data,
		isLoading: !error && !data,
		error,
	};
};

const useAdLineQL = (shouldLoad, symbol) => {
	const queryQL = `
		query MyQuery {
			indexAdLine(symbol: "${symbol}") {
				adLine
				advancedCount
				date
				declinedCount
			}
		}
	`;

	const { data, error } = useSWRImmutable(shouldLoad ? queryQL : null, fetcherGraphQL, {});

	return {
		data: data && data.indexAdLine ? data.indexAdLine : data,
		isLoading: !error && !data,
		error,
	};
};

const useClosesQL = (shouldLoad, symbol, countback, resolution = '1d') => {
	const queryQL = `
		query MyQuery {
			bars(countback: ${countback}, symbol: "${symbol}", resolution: "${resolution}") {				
				date
				close
			}
		}
	`;

	const { data, error } = useSWRImmutable(shouldLoad ? queryQL : null, fetcherGraphQL, {});

	return {
		bars: data && data.bars ? data.bars : data,
		isLoading: !error && !data,
		error,
	};
};

const useVolumesQL = (shouldLoad, symbol, countback, resolution = '1d') => {
	const queryQL = `
		query MyQuery {
			bars(countback: ${countback}, symbol: "${symbol}", resolution: "${resolution}") {				
				date
				volume
			}
		}
	`;

	const { data, error } = useSWRImmutable(shouldLoad ? queryQL : null, fetcherGraphQL, {});

	return {
		bars: data && data.bars ? data.bars : data,
		isLoading: !error && !data,
		error,
	};
};

export const CACHED_SECTORS_DATA = 'lightstock_sectors_data';

const useSectorsQL = (shouldLoad) => {
	const queryQL = `
		query MyQuery {
			sectors {
				lastClose
				lastHMax
				lastHMin
				lastVolume
				name
				percChangeOneDay
				percChangeOneYear
				percChangeOneMonth
				percChangeOneWeek
				percChangeThreeMonths
				percChangeThreeYears
				percChangeFiveYears
				percChangeTenYears
				percChangeAllTime
				symbol
				prevClose
				yearMaxClose
				yearMinClose
			}
		}
	`;

	const { data, error } = useSWRImmutable(shouldLoad ? queryQL : null, fetcherGraphQL, {});
	return {
		sectors: data && data.sectors ? data.sectors : data,
		isLoading: !error && !data,
		error,
	};
};

const useSectorStocksQL = (shouldLoad, name) => {
	const queryQL = `
		query MyQuery {
			sectorStocks(name: "${name}") {
				pagination {
					currentPage
					pageSize
					totalItems
					totalPages
				}
				items {
						gicIndustry
						gicSector
						lastClose
						lastHMax
						lastHMin
						lastVolume
						logoUrl
						marketCap
						name
						percChangeOneDay
						percChangeOneYear
						percChangeOneMonth
						percChangeOneWeek
						percChangeThreeMonths
						percChangeAllTime
						prevClose
						symbol
						yearMaxClose
						yearMinClose
						trendPhase
						trendPhaseDuration
						rankLongTrend
						rankMidTrend
						rankMomentum
						rankVolume
						rankVolatility
						rank
						scoreLongTrend
						scoreMidTrend
						scoreMomentum
						scoreVolume
						scoreVolatility
						score
				}
			}	
		}
	`;

	const { data, error, isLoading } = useSWRImmutable(
		shouldLoad ? queryQL : null,
		fetcherGraphQL,
		{
			keepPreviousData: false,
			revalidateOnFocus: false,
			refreshInterval: 30000, // 30 seconds
		},
	);

	return {
		items: data && data.sectorStocks ? data.sectorStocks.items : data,
		pagination: data && data.sectorStocks ? data.sectorStocks.pagination : data,
		isLoading,
		error,
	};
};

export const CACHED_INDEXES_DATA = 'lightstock_indexes_data';

const useIndexesQL = (shouldLoad) => {
	const queryQL = `
		query MyQuery {
			indexesSummaries {
				lastClose
				lastHMax
				lastHMin
				lastVolume
				name
				percChangeOneDay
				percChangeOneYear
				percChangeOneMonth
				percChangeOneWeek
				percChangeThreeMonths
				percChangeAllTime
				symbol
				prevClose
				yearMaxClose
				yearMinClose
			}
		}
	`;

	const { data, error } = useSWR(shouldLoad ? queryQL : null, fetcherGraphQL, {});
	return {
		indexes: data && data.indexesSummaries ? data.indexesSummaries : data,
		isLoading: !error && !data,
		error,
	};
};

export const CACHED_INDEX_COMPONENTS_DATA = 'lightstock_index_components_data';

const useIndexComponentsQL = (symbol, shouldLoad = true) => {
	const queryQL = `
		query MyQuery {
			indexComponents(symbol: "${symbol}") {
				pagination {
					currentPage
					pageSize
					totalItems
					totalPages
				}
				items {
						gicIndustry
						gicSector
						lastClose
						lastHMax
						lastHMin
						lastVolume
						logoUrl
						marketCap
						name
						percChangeOneDay
						percChangeOneYear
						percChangeOneMonth
						percChangeOneWeek
						percChangeThreeMonths
						percChangeAllTime
						prevClose
						symbol
						yearMaxClose
						yearMinClose
						trendPhase
						trendPhaseDuration
						rankLongTrend
						rankMidTrend
						rankMomentum
						rankVolume
						rankVolatility
						rank
						scoreLongTrend
						scoreMidTrend
						scoreMomentum
						scoreVolume
						scoreVolatility
						score
				}
			}	
		}
	`;

	const { data, error, isLoading } = useSWR(shouldLoad ? queryQL : null, fetcherGraphQL, {
		keepPreviousData: false,
		revalidateOnFocus: false,
		refreshInterval: 30000, // 30 seconds
	});

	return {
		items: data && data.indexComponents ? data.indexComponents.items : data,
		pagination: data && data.indexComponents ? data.indexComponents.pagination : data,
		isLoading,
		error,
	};
};

export const CACHED_INSTRUMENTS_ITEMS = 'lightstock_instruments_items';
export const CACHED_INSTRUMENTS_PAGINATION = 'lightstock_instruments_pagination';

const useScreenerResultsQL = (
	timeframe,
	query,
	lookback,
	page,
	pageSize,
	sortConfig,
	maxResults,
) => {
	const shouldFetch = true;
	const queryQL = gql`query GetScreenerResults {
		screener(
		  timeframe: ${timeframe}
		  criteria: ${query}
		  lookback: ${lookback}
		  page: ${page}
		  pageSize: ${pageSize}
		  sortBy: "${sortConfig ? sortConfig.key : ''}"
		  sortAsc: ${sortConfig ? sortConfig.direction === 'ascending' : false}
		  maxResults: ${maxResults || 5000}
		) {
		  pagination {
			currentPage
			pageSize
			totalItems
			totalPages
		  }
		  items {
			gicIndustry
			gicSector
			lastClose
			lastHMax
			lastHMin
			lastVolume
			logoUrl
			marketCap
			name
			percChangeOneDay
			percChangeOneYear
			percChangeOneMonth
			percChangeOneWeek
			percChangeThreeMonths
			percChangeAllTime
			prevClose
			symbol
			yearMaxClose
			yearMinClose
			trendPhase
			trendPhaseDuration
			rankLongTrend
			rankMidTrend
			rankMomentum
			rankVolume
			rankVolatility
			rank
			scoreLongTrend
			scoreMidTrend
			scoreMomentum
			scoreVolume
			scoreVolatility
			score
		  }
		}
	  }`;

	const { data, error, isLoading } = useSWR(shouldFetch ? queryQL : null, fetcherGraphQL, {
		keepPreviousData: true,
		revalidateOnFocus: false,
		refreshInterval: 30000, // 30 seconds
		onSuccess(response) {
			const { items, pagination } = response.screener;
			localStorage.setItem(CACHED_INSTRUMENTS_ITEMS, JSON.stringify(items));
			localStorage.setItem(CACHED_INSTRUMENTS_PAGINATION, JSON.stringify(pagination));
		},
		onFailure() {
			localStorage.removeItem(CACHED_INSTRUMENTS_ITEMS);
			localStorage.removeItem(CACHED_INSTRUMENTS_PAGINATION);
		},
	});

	return {
		items: data && data.screener ? data.screener.items : data,
		pagination: data && data.screener ? data.screener.pagination : data,
		isLoading,
		error,
	};
};

const useAnalyzeChartQL = (symbol, shouldFetch) => {
	const queryQL = `
		query MyQuery {
		 	analyzeChart(symbol: "${symbol}") {
				patterns
				analysis
				encodedImage
			}		
		}
	`;

	const { data, error } = useSWR(shouldFetch ? queryQL : null, fetcherGraphQL, {});
	return {
		analyzeChart: data && data.analyzeChart ? data.analyzeChart : data,
		isLoading: !error && !data,
		error,
	};
};

const useFindStocksBySymbolOrName = (queryText, shouldFetch) => {
	const queryQL = `
		query MyQuery {
		 	findStocksBySymbolOrName(queryText: "${queryText}") {
				symbol
				name
				logoUrl
  			}	
		}
	`;

	const { data, error } = useSWR(shouldFetch ? queryQL : null, fetcherGraphQL, {});
	return {
		items: data && data.findStocksBySymbolOrName ? data.findStocksBySymbolOrName : data,
		isLoading: !error && !data,
		error,
	};
};

const useWatchlist = () => {
	return JSON.parse(localStorage.getItem('watchlist')) || [];
};

const addToWatchlist = (symbol) => {
	const watchlist = JSON.parse(localStorage.getItem('watchlist')) || [];
	const newWatchlist = [...watchlist, { symbol }];
	localStorage.setItem('watchlist', JSON.stringify(newWatchlist));
};

const removeFromWatchlist = (symbol) => {
	const watchlist = JSON.parse(localStorage.getItem('watchlist')) || [];
	const newWatchlist = watchlist.filter((item) => item.symbol !== symbol);
	localStorage.setItem('watchlist', JSON.stringify(newWatchlist));
};

const isSymbolInWatchlist = (symbol) => {
	const watchlist = JSON.parse(localStorage.getItem('watchlist')) || [];
	return watchlist.some((item) => item.symbol === symbol);
};

// eslint-disable-next-line import/prefer-default-export
export {
	BASE_URL,
	httpClient,
	useStockQL,
	useExchangesQL,
	useBarsQL,
	useBarsPeriodQL,
	useClosesQL,
	useVolumesQL,
	useAllFilters,
	useBars,
	useFearGreed,
	useChartImage,
	useMarkdown,
	useScreenerResultsQL,
	useSectorsQL,
	useSectorStocksQL,
	useIndexesQL,
	useIndexComponentsQL,
	useAnalyzeChartQL,
	useWatchlist,
	addToWatchlist,
	removeFromWatchlist,
	isSymbolInWatchlist,
	useFindStocksBySymbolOrName,
	useBusinessCycle,
	useIndexesBarsQL,
	useIndexesSymbolsQL,
	useAdLineQL,
};
