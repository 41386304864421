import * as React from 'react';
import type { SVGProps } from 'react';
const SvgReliabilityVeryLow = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='1em'
		height='1em'
		viewBox='0 0 24 24'
		className='svg-icon'
		{...props}>
		<path
			d='M12 21 0 9a16.374 16.374 0 0 1 5.488-3.715A16.921 16.921 0 0 1 12 4c2.266 0 4.438.43 6.512 1.285A16.374 16.374 0 0 1 24 9Zm0-2.852 9.102-9.097c-1.317-1-2.75-1.758-4.301-2.278A15.123 15.123 0 0 0 12 6c-1.648 0-3.25.258-4.8.773a14.875 14.875 0 0 0-4.302 2.278Zm0 0'
			style={{
				stroke: 'none',
				fillRule: 'nonzero',
				fill: '#fe0200',
				fillOpacity: 1,
			}}
		/>
	</svg>
);
export default SvgReliabilityVeryLow;
