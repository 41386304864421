import * as React from 'react';
import type { SVGProps } from 'react';
const SvgAccumulation = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='1em'
		height='1em'
		viewBox='0 0 100 100'
		className='svg-icon'
		{...props}>
		<path stroke='orange' strokeWidth={15} d='M10 50h80' />
		<circle cx={20} cy={50} r={10} fill='orange' />
		<circle cx={40} cy={50} r={10} fill='orange' />
		<circle cx={60} cy={50} r={10} fill='orange' />
		<circle cx={80} cy={50} r={10} fill='orange' />
		<path fill='orange' d='m90 50 10-5v10z' />
	</svg>
);
export default SvgAccumulation;
