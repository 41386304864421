import React from 'react';
import Search from 'components/Search';
import BuyMeCoffee from 'components/BuyMeCoffee';
import Header, { HeaderLeft, HeaderRight } from '../../../layout/Header/Header';
import Navigation from '../../../layout/Navigation/Navigation';
import { topPagesMenu } from '../../../menu';
import useDeviceScreen from '../../../hooks/useDeviceScreen';
import Alert from 'components/bootstrap/Alert';

const DefaultHeader = () => {
	const { width } = useDeviceScreen();
	const visible = true;

	if (!visible) return <br />;

	return (
		<Header>
			<HeaderLeft>
				<Navigation
					menu={{ ...topPagesMenu }}
					id='header-top-menu'
					horizontal={
						!!width && width >= Number(process.env.REACT_APP_MOBILE_BREAKPOINT_SIZE)
					}
				/>
			</HeaderLeft>
			<HeaderRight>
				<div className='row g-3 align-items-center'>
					<div className='col'>
						<Search />
					</div>
					<div className='col-auto'>
						<BuyMeCoffee />
					</div>
				</div>
			</HeaderRight>
			<Alert color='warning' isLight className='text-center'>
				<small>
					<span className='fw-bold'>Disclaimer:</span> At the moment, I can't afford the
					necessary costs for data and servers. For this reason, the data has not been
					updated since April 2. Thank you for your understanding. You never know—maybe in
					the future, I'll secure investment and support from you..
				</small>
			</Alert>
		</Header>
	);
};

export default DefaultHeader;
