import * as React from 'react';
import type { SVGProps } from 'react';
const SvgBullishLittle = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='1em'
		height='1em'
		className='svg-icon'
		{...props}>
		<path
			fill='#08dd08'
			stroke='#0cd60c'
			d='m2.478 6.226 3.65-5.194 3.648 5.194H7.952v5.22h-3.65v-5.22H2.479z'
		/>
	</svg>
);
export default SvgBullishLittle;
