import * as React from 'react';
import type { SVGProps } from 'react';
const SvgBullish = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='1em'
		height='1em'
		viewBox='0 0 1200 1200'
		className='svg-icon'
		{...props}>
		<path
			fill='#fff'
			d='M94.219 123.52V1037.939c0 12.305-.234 24.727 0 37.031v1.64c0 15.82 13.477 29.298 29.297 29.298H1037.935c12.305 0 24.727.234 37.031 0h1.64c15.353 0 30-13.477 29.298-29.297-.704-15.82-12.891-29.297-29.297-29.297H162.188c-12.305 0-24.727-.235-37.031 0h-1.641l29.297 29.297V162.192c0-12.305.234-24.727 0-37.031v-1.641c0-15.352-13.477-30-29.297-29.297-15.938.703-29.297 12.891-29.297 29.297z'
		/>
		<path
			fill='#fff'
			d='M144.14 902.81c43.008-35.742 86.016-71.602 129.02-107.34 6.094-5.04 12.07-10.078 18.164-15.117H249.84l64.688 56.25c34.219 29.766 68.438 59.531 102.66 89.297 7.852 6.914 15.82 13.711 23.672 20.625 11.133 9.727 30.703 12.773 41.484 0 16.055-18.984 32.109-37.852 48.047-56.836 38.438-45.469 76.992-90.938 115.43-136.52 46.523-54.961 93.047-110.04 139.57-165 39.961-47.344 80.039-94.57 120-141.91 19.57-23.086 39.844-45.703 58.711-69.375.234-.352.586-.703.82-.938h-41.484c38.555 30.703 77.11 61.523 115.66 92.227 5.508 4.454 11.016 8.79 16.523 13.242 6.328 5.04 12.305 8.555 20.742 8.555 7.149 0 15.82-3.164 20.742-8.554 9.727-10.664 12.773-31.172 0-41.484-38.555-30.703-77.109-61.523-115.66-92.227-5.507-4.454-11.016-8.79-16.523-13.242-5.976-4.805-12.539-8.907-20.742-8.555-7.382.351-15.703 2.695-20.742 8.554-16.055 18.985-32.109 37.853-48.047 56.837-38.438 45.469-76.992 90.938-115.43 136.52-46.523 54.96-93.047 110.04-139.57 165-39.96 47.344-80.039 94.57-120 141.91-19.57 23.086-39.844 45.703-58.71 69.375-.235.351-.587.703-.821.937h41.484l-64.688-56.25c-34.219-29.766-68.438-59.531-102.66-89.297-7.852-6.914-15.82-13.711-23.672-20.625-12.773-11.133-28.477-10.781-41.484 0-43.008 35.742-86.016 71.602-129.02 107.34-6.094 5.04-12.07 10.078-18.164 15.117-11.836 9.844-10.898 31.406 0 41.484 12.656 11.484 28.945 10.43 41.484 0z'
		/>
		<path
			fill='#fff'
			d='M450.12 215.74v349.098c0 15.352 13.477 30 29.297 29.297 15.82-.703 29.297-12.891 29.297-29.297V215.74c0-15.352-13.477-30-29.297-29.297-15.82.703-29.297 12.891-29.297 29.297z'
		/>
		<path
			fill='#fff'
			d='m354.49 382.15 49.219-49.219 78.398-78.398 18.047-18.047H458.67l145.66 145.66c10.898 10.898 30.703 11.602 41.484 0 10.781-11.719 11.602-29.883 0-41.484l-145.66-145.66c-11.133-11.133-30.234-11.25-41.484 0l-49.219 49.219-78.398 78.398-18.047 18.047c-10.898 10.898-11.602 30.703 0 41.484 11.719 10.781 29.883 11.602 41.484 0z'
		/>
	</svg>
);
export default SvgBullish;
